import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import 'gatsby-remark-vscode/styles.css'

function PostTemplate({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, fields, html } = markdownRemark

  const coverPicture = frontmatter.coverPicture
    ? getImage(frontmatter.coverPicture).images.fallback.src
    : `/${pageContext.coverPicture.split("/").pop()}`

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        keywords={frontmatter.keywords}
        description={frontmatter.excerpt}
        image={coverPicture}
        article={true}
      />
      <article className="flex-col max-w-4xl px-3 mx-auto md:px-6 dark:text-gray-200">
        {frontmatter.coverPicture !== null && (
          <GatsbyImage
            alt={`${frontmatter.title} Cover`}
            image={getImage(frontmatter.coverPicture)}
            className="w-full mb-4 shadow-lg"
          />
        )}
        <time className="block mb-2 text-xs tracking-widest uppercase">{fields.date}</time>
        <h1 className="mb-6 text-3xl font-bold leading-tights">{frontmatter.title}</h1>
        <div
          className="markdown-body"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PostTemplate

export const pageQuery = graphql`
  query PostQuery($uri: String!) {
    markdownRemark(fields: {uri: {eq: $uri}}) {
      html
      frontmatter {
        title
        keywords
        excerpt
        coverPicture {
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
      }
      fields {
        date: prefix(formatString: "MMMM DD, YYYY")
        slug
        uri
      }
    }
  }
`